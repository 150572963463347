




















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { ISenderBan, ISenderBanCreate } from '@/interfaces';
import {
  dispatchCreateSenderBan,
  dispatchDeleteSenderBan,
  dispatchDeleteSenderBans,
  dispatchGetSenderBans,
  dispatchGetSettings,
  readAdminUsers,
  readSenderBans,
  readSettings,
} from '@/store/admin/accessors';
import VTextFieldWithValidation from '@/components/VTextFieldWithValidation.vue';
import { ValidationProvider } from 'vee-validate';
import ConfirmDialog from '@/components/ConfirmDialog.vue';

@Component({
  components: {
    VTextFieldWithValidation,
    ConfirmDialog,
  },
})
export default class SenderBans extends Vue {
  public search: string = '';
  public loading: boolean = false;
  public moSenderBans: ISenderBan[] = [];
  public totalSenderBans: number = 0;

  public selected: ISenderBan[] = [];
  public pagination: { sortBy; descending; page; rowsPerPage; footerProps } = {
    sortBy: 'id',
    descending: false,
    page: 1,
    rowsPerPage: 20,
    footerProps: { 'items-per-page-options': [10, 20, 50, 100] },
  };
  public headers = [
    {
      text: this.$vuetify.lang.t('$vuetify.phoneNumber'),
      value: 'phone',
      align: 'left',
    },
    {
      text: this.$vuetify.lang.t('$vuetify.banExpiration'),
      value: 'expiration_dt',
      align: 'left',
    },
    {
      text: this.$vuetify.lang.t('$vuetify.actions'),
      value: 'id',
    },
  ];

  public dialog = false;
  public phoneNumber: string = '';
  public banDuration: number = 0;
  public valid = false;
  public $refs!: {
    observer: InstanceType<typeof ValidationProvider>;
    confirm: InstanceType<typeof ConfirmDialog>;
  };
  private defaultBanDuration = 0;
  private searchDelay: number | undefined;

  public updateSenderBans() {
    this.loading = true;
    this.getDataFromApi().then((data) => {
      if (typeof data === 'number') {
        this.totalSenderBans = data;
        this.moSenderBans = readSenderBans(this.$store);
      }
    });
    this.loading = false;
  }

  @Watch('pagination', { deep: true })
  public onPaginationChange(val) {
    this.updateSenderBans();
  }

  @Watch('search')
  public onSearchChange(val) {
    clearTimeout(this.searchDelay);
    this.searchDelay = setTimeout(this.updateSenderBans, 400);
  }

  public async mounted() {
    await dispatchGetSettings(this.$store);
    const settings = readSettings(this.$store);
    for (const setting of settings) {
      if (setting.key === 'BAN_DURATION') {
        this.defaultBanDuration = Number(setting.value);
        this.banDuration = this.defaultBanDuration;
      }
    }
    this.updateSenderBans();
  }

  public async deleteSenderBan(sender: string) {
    if (await this.$refs.confirm.open(this.$vuetify.lang.t('$vuetify.deleteEntryConfirmation'))) {
      await dispatchDeleteSenderBan(this.$store, sender);
      this.updateSenderBans();
    }
  }
  public async deleteSenderBans() {
    if (await this.$refs.confirm.open(this.$vuetify.lang.t('$vuetify.deleteEntriesConfirmation'))) {
      const selected = this.selected.map((item) => item.phone as string);
      await dispatchDeleteSenderBans(this.$store, selected);
      this.updateSenderBans();
    }
  }

  public async getDataFromApi() {
    const { sortBy, descending, page, rowsPerPage } = this.pagination;
    const skip = (page - 1) * rowsPerPage;
    return await dispatchGetSenderBans(this.$store, {
      skip,
      limit: rowsPerPage,
      sender: this.search,
    });
  }

  @Watch('dialog')
  public onDialogChange(val) {
    return val || this.dialogClose();
  }

  public dialogClose() {
    this.phoneNumber = '';
    this.banDuration = this.defaultBanDuration;
    this.dialog = false;
    this.$refs.observer.reset();
  }

  public async submit() {
    if (await this.$refs.observer.validate()) {
      const payload: ISenderBanCreate = {
        phone: this.phoneNumber,
        duration: this.banDuration,
      };
      await dispatchCreateSenderBan(this.$store, payload);
      this.search = this.phoneNumber;
      this.updateSenderBans();
      this.dialog = false;
    }
  }
}
